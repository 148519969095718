<template>
  <h1>Oops, it looks like the page you're looking for doesn't exist.</h1>
</template>

<script lang="ts">
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'NotFound',
});
</script>

<style scoped>

</style>
